import {
  Component,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { APIService } from '../../../../api/api.service';
import { NotifierService } from '../../../../_services/notifier.service';
import { regions } from './../../../../dash/dash.component';

declare let $: any;
declare let flatpickr: any;

@Component({
  selector: 'enables3-update',
  templateUrl: './updates3.component.html',
  styleUrls: ['./updates3.component.css'],
})


export class UpdateS3Component implements OnInit, OnDestroy {
  additionalContacts: string[] = [''];
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  regions: any = regions;
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  accountId: any;
  regionId: any;
  dateRange: any;
  selectAll: boolean = false;
  RITypes: any = [];
  currentMessage: any;
  @Output() hideModel = new EventEmitter<any>();
  @Input() ri: any;
  @Input('action') action: any;
  instanceTypes: any;
  platformTypes: any;
  linkedAccounts: any ={};
  selectedAccounts: any;
  accounts: any;
  constructor(
    private apiService: APIService,
    private notifier: NotifierService
  ) {}

  ngOnInit(): void {
    this.ri = { ...this.ri };
    $('#regionsList').selectpicker();
    setTimeout(() => {
      $('#regionsList').selectpicker('refresh');
      $('#platformsList').selectpicker('refresh');
      // $('#select_all_childs').selectpicker('refresh');
    }, 1000);
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');
    this.linkedAccounts = JSON.parse(localStorage.getItem('accountIdData') || '{}');
    if (Array.isArray(this.linkedAccounts.list)) {
      this.accounts = this.linkedAccounts.list.map((accountObj:any) => accountObj.account);
    } else {
      console.error('linkedAccounts does not have a valid list key or it is not an array.');
    }
    setTimeout(() => {
      $('#linkedAccounts').selectpicker('refresh');
    }, 100);
  

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load();
      }
      else if (d.key == 'accountIdData'){
        this.linkedAccounts = d.value;
        this.load();
      }
    });
    flatpickr('#dataRangePicker', {
      dateFormat: 'Y-m-d',
      mode: 'range',
      showMonths: 2,
      defaultDate: this.dateRange ? this.dateRange.split(' to ') : null,
      onChange: (selectedDates: any, dateStr: any, instance: any) => {
        if (!dateStr.includes('to')) return;
        this.dateRange = dateStr;
      },
    });

    if (this.action == 'update') {
      if (this.ri.discountType == 'value') {
        this.disc = this.ri.discount;
      } else {
        this.disc = this.ri.discountPercentage;
      }
      this.dateRange = this.ri.startDate + ' to ' + this.ri.endDate;
      if (this.ri.usageType == 'all') {
        this.selectAll = true;
      }
    }
  }


  disc: any;
  load() {
    this.fetchInstanceTypes();
    if (this.action == 'update' && this.ri.usageType !== 'all') {
      this.fetchPlatformsTypes();
    }
    // this.fetchRITypes();
    // this.fetchPlatformsTypes();
  }
  validateNumericInput(event: KeyboardEvent): void {
    // Allow only numeric characters (0-9) and control keys (backspace, delete, arrows)
    const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'];
    if (allowedKeys.includes(event.key)) {
      return; // Allow control keys
    }
    
    // Block any non-numeric keys
    const isNumeric = /^[0-9]$/.test(event.key);
    if (!isNumeric) {
      event.preventDefault();
    }
  }
  

  selectAllRegions(event: any) {
    if (event.target.checked) {
      this.ri.regionn = this.regions_reg.map((region: any) => region);
    } else {
      this.ri.regionn = [];
    }

    this.fetchPlatformsTypes();

    setTimeout(() => {
      $('#regions_reg').selectpicker('refresh');
    }, 10);
  }

  selectAllchilds(event: any) {
    const isChecked = event.target.checked;

    if (isChecked) {
      // Select all accounts
      this.ri.linkedAccounts = this.linkedAccounts['list'].map((account:any) => account.accountId);
    } else {
      // Deselect all accounts
      this.ri.linkedAccounts = [];
    }
    setTimeout(() => {
      $('#linkedAccounts').selectpicker('refresh');
    }, 10);
    
  }

  selectFiltertedRegions(event: Event) {
    let input = document
      .querySelector('#regions_reg')
      .parentElement.querySelector('.dropdown-menu')
      .querySelector('input');

    if (input.value.trim() == '') {
      return;
    }

    this.ri.regionn = this.regions_reg.filter(
      (region: any) =>
        region.toLowerCase().indexOf(input.value.toLowerCase()) > -1
    );

    this.fetchPlatformsTypes();

    setTimeout(() => {
      $('#regions_reg').selectpicker('refresh');
    }, 10);
  }

  selectAllUsageTypes(event: any) {
    if (event.target.checked) {
      this.ri.usageType = this.usageType.map((platform: any) => platform);
    } else {
      this.ri.usageType = [];
    }
    setTimeout(() => {
      $('#platformsList').selectpicker('refresh');
    }, 10);
  }

  selectFiltertedUsageTypes(event: Event) {
    let input = document
      .querySelector('#platformsList')
      .parentElement.querySelector('.dropdown-menu')
      .querySelector('input');

    if (input.value.trim() == '') {
      return;
    }

    this.ri.usageType = this.usageType.filter(
      (platform: any) =>
        platform.toLowerCase().indexOf(input.value.toLowerCase()) > -1
    );

    setTimeout(() => {
      $('#platformsList').selectpicker('refresh');
    }, 10);
  }

  onSelectAllChange() {
    this.ri.usageType = this.selectAll ? 'all' : '';
  }
  async fetchRICost() {
    if (this.ri.regionn.length > 1 || this.ri.usageType.length > 1) {
      return;
    } else if (this.ri.usageType.length !== 0) {
      let region: string = $('#regionn').val();
      let usageType: string = $('#usageType').val();
      this.notifier.loading(true);
      let data = {
        action: 'getUsageTypeDetails',
        usageType: this.ri.usageType[0].split('_')[0],
        region: this.ri.regionn[0],
      };
      let header = {
        Authorization: localStorage.getItem('t'),
      };

    let apiURL = `https://dev.api.swayam.cloud/v3/admin/billing/s3-enable`;
    // let apiURL = `https://dev.api.swayam.cloud/v3/admin/billing/cfrc-test`;

      let result = await this.apiService.postDataPromis(apiURL, data, header);
      if (result.status == '1' || result.s == '1') {
        let response;
        response = result.data[0];
        this.ri = {
          ...this.ri,
          description: response.description,
          discount: response.discount,
          // regionn: response.regionn,
          // usageType: response.usageType,
          unit: response.unit,
          ppu: response.ppu,
        };
      } else {
        this.notifier.alert('Info', '', result.msg, 'info', 5000);
      }

      this.notifier.loading(false);
    }
  }
  regions_reg: any;
  async fetchInstanceTypes() {
    this.notifier.loading(true);
    let data = { action: 'fetchUniqueRegions' };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `https://dev.api.swayam.cloud/v3/admin/billing/s3-enable`;
    // let apiURL = `https://dev.api.swayam.cloud/v3/admin/billing/cfrc-test`;

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.regions_reg = result.regions;
      setTimeout(() => {
        $('#regions_reg').selectpicker('refresh');
      }, 100);
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }
    this.notifier.loading(false);
  }

  // async fetchRITypes() {
  //   this.notifier.loading(true);
  //   let data = { action: 'fetchBillingType', accountId: this.accountId };

  //   let header = {
  //     Authorization: localStorage.getItem('t'),
  //   };
  //   //https://api.swayam.cloud/v3/admin/support
  //   let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/bom/fetchbomdetails`;

  //   let result = await this.apiService.postDataPromis(apiURL, data, header);

  //   if (result.status == '1' || result.s == '1') {
  //     this.RITypes = result.billingTypeList;
  //     setTimeout(() => {
  //       $('#billingTypes').selectpicker('refresh');
  //     }, 100);
  //   } else {
  //     this.notifier.alert('Info', '', result.error, 'info', 5000);
  //   }
  // }
  usageType: any;
  async fetchPlatformsTypes() {
    this.notifier.loading(true);
    let data = {
      action: 'getRegionWiseUsageTypes',
      regionList: this.ri.regionn,
      childIds: this.ri.linkedAccounts,
      accountId: this.accountId,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };
    //https://api.swayam.cloud/v3/admin/support
    let apiURL = `https://dev.api.swayam.cloud/v3/admin/billing/s3-enable`;
    // let apiURL = `https://dev.api.swayam.cloud/v3/admin/billing/cfrc-test`;

    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (result.status == '1' || result.s == '1') {
      this.usageType = result.usageType;
      setTimeout(() => {
        $('#platformsList').selectpicker('refresh');
      }, 100);
    } else {
      this.notifier.alert('Info', '', result.error, 'info', 5000);
    }
    this.notifier.loading(false);
  }
  isFormValid() {
    return (
      !!this.ri.regionn &&
      !!this.ri.usageType &&
      !!this.ri.discountPercentage &&
      !!this.ri.unit &&
      !!this.ri.ppu
    );
  }
  showInvalidFieldNotification() {
    if (this.ri.regionn.length === 0) {
      this.notifier.alert('Error', '', 'Region is required', 'error', 5000);
      return;
    }
    if (this.ri.usageType.length === 0) {
      this.notifier.alert('Error', '', 'Usage Type is required', 'error', 5000);
      return;
    }
    if (!this.ri.discountPercentage) {
      this.notifier.alert('Error', '', 'Discount is required', 'error', 5000);
      return;
    }
    // if (!this.ri.unit) {
    //   this.notifier.alert('Error', '', 'Unit is required', 'error', 5000);
    //   return;
    // }
    // if (!this.ri.ppu) {
    //   this.notifier.alert('Error', '', 'Price Per Unit is required', 'error', 5000);
    //   return;
    // }
  }

  async updateEntry() {
    if (!this.selectAll && this.ri.regionn.length === 0) {
      this.notifier.alert('Error', '', 'Region is required', 'error', 5500);
      return;
    }
    if (this.ri.usageType !== 'all' && this.ri.usageType.length === 0) {
      this.notifier.alert('Error', '', 'Usage Type is required', 'error', 5500);
      return;
    }

    if (
      (this.disc == undefined || this.disc == '') &&
      this.action == 'update'
    ) {
      this.notifier.alert('Error', '', 'Discount  is required', 'error', 5500);
      return;
    }

    if (
      (this.ri.discountPercentage === '' || this.ri.discountPercentage === undefined ||
        !/^\d+(\.\d+)?$/.test(this.ri.discountPercentage)) &&
      this.action !== 'update'
    ) {
      this.notifier.alert('Error', '', 'Discount is required and must be numeric', 'error', 5500);
      return;
    }
    // if(this.ri.discountType == 'value'){
    //   if(this.ri.discount == '' || this.ri.discount == undefined){
    //     this.notifier.alert('Error', '', 'Discount value  is required', 'error', 5500);
    //     return
    //   }

    // }
    // if(this.ri.discountType !== 'value'){
    if (
      (this.ri.discountPercentage == '' ||
        this.ri.discountPercentage == undefined) &&
      this.action !== 'update'
    ) {
      this.notifier.alert('Error', '', 'Discount is required', 'error', 5500);
      return;
      // }
    }
    if (this.dateRange == '' || this.dateRange == undefined) {
      this.notifier.alert('Info', '', 'Please select date range', 'info', 5500);
      return;
    }
    if (this.action !== 'update') {
      this.notifier.loading(true);

      const start = this.dateRange.split('to')[0].trim();
      const end = this.dateRange.split('to')[1].trim();
      let data: any;
      if (
        (this.ri.regionn.length > 1 || this.ri.usageType.length > 1) &&
        !this.selectAll
      ) {
        data = {
          action: 'addMultiple',
          childIds: this.ri.linkedAccounts,
          regionList: this.ri.regionn,
          usageTypelist: this.ri.usageType,
          startDate: start,
          endDate: end,
          discountType: this.ri.discountType,
          discount: this.ri.discountPercentage,
          ppu: this.ri.ppu,
        };
      } else {
        let usageType: string = '';
        let regionn: string = '';

        // Check if selectAll is true
        if (this.selectAll) {
          usageType = 'all';
          regionn = '';
        } else {
          // Extract values from the ri object
          usageType = this.ri.usageType[0].split('_')[0];
          regionn = this.ri.regionn[0];
        }
        data = {
          action: 'add',
          childIds: this.ri.linkedAccounts,
          data: [
            {
              regionn: regionn,
              description: this.ri.description,
              ppu: this.ri.ppu,
              discountType: this.ri.discountType,
              unit: this.ri.unit,
              usageType: usageType,
              discount: this.ri.discountPercentage,
              startDate: start,
              endDate: end,
            },
          ],
        };
      }
      let header = {
        Authorization: localStorage.getItem('t'),
      };

    let apiURL = `https://dev.api.swayam.cloud/v3/admin/billing/s3-enable`;
    // let apiURL = `https://dev.api.swayam.cloud/v3/admin/billing/cfrc-test`;

      let result = await this.apiService.postDataPromis(apiURL, data, header);

      if (result.status == '1' || result.s == '1') {
        this.notifier.alert('Success', '', result.msg, 'success', 5000);
        this.close(true);
      } else {
        this.notifier.alert('Info', '', result.msg, 'info', 5000);
      }
      this.notifier.loading(false);
    } else {
      this.notifier.loading(true);

      let data: any = {
        action: 'update',
        data: [
          {
            accountId: this.ri.accountId,
            uuid: this.ri.uuid,
            discountType: this.ri.discountType,
            discount: this.disc,
            ppu: this.ri.ppu,
            usageType: this.ri.usageType,
          },
        ],
      };

      let header = {
        Authorization: localStorage.getItem('t'),
      };
    let apiURL = `https://dev.api.swayam.cloud/v3/admin/billing/s3-enable`;
    // let apiURL = `https://dev.api.swayam.cloud/v3/admin/billing/cfrc-test`;

      let result = await this.apiService.postDataPromis(apiURL, data, header);

      if (result.status == '1' || result.s == '1') {
        this.notifier.alert('Success', '', result.msg, 'success', 5000);
        this.close(true);
      } else {
        this.notifier.alert('Info', '', result.msg, 'info', 5000);
      }

      this.notifier.loading(false);
    }
  }

  getDisplayName(type: string): string {
    return type.split('_')[0];
  }
  ngOnDestroy(): void {}

  trackByFn() {
    return true;
  }
  showInvalidFieldNotificationall() {
    if (!this.ri.usageType) {
      this.notifier.alert('Error', '', 'Usage Type is required', 'error', 5000);
      return;
    }
    if (!this.ri.discountPercentage) {
      this.notifier.alert('Error', '', 'Discount is required', 'error', 5000);
      return;
    }
    if (this.dateRange == '' || this.dateRange == undefined) {
      this.notifier.alert('Info', '', 'Please select date range', 'info', 500);
      return;
    }
  }

  async updateEntryAll() {
    if (this.action !== 'update') {
      if (!this.ri.usageType) {
        this.notifier.alert(
          'Error',
          '',
          'Usage Type is required',
          'error',
          5000
        );
        return;
      }

      if (this.dateRange == '' || this.dateRange == undefined) {
        this.notifier.alert(
          'Info',
          '',
          'Please select date range',
          'info',
          500
        );
        return;
      }

      if (this.ri.discountType == 'percentage') {
        if (!this.ri.discountPercentage) {
          this.notifier.alert(
            'Error',
            '',
            'Discount  is required',
            'error',
            5000
          );
          return;
        }
      }
      if (this.ri.discountType == 'value') {
        if (!this.ri.discount) {
          this.notifier.alert(
            'Error',
            '',
            'Discount is required',
            'error',
            5000
          );
          return;
        }
      }

      const start = this.dateRange.split('to')[0].trim();
      const end = this.dateRange.split('to')[1].trim();
      this.notifier.loading(true);

      let data: any = {
        action: 'add',
        accountId: this.ri.accountId,
        data: [
          {
            startDate: start,
            endDate: end,
            usageType: 'all',
            discount: this.ri.discountPercentage,
            discountType: this.ri.discountType,
          },
        ],
      };
      let header = {
        Authorization: localStorage.getItem('t'),
      };
    let apiURL = `https://dev.api.swayam.cloud/v3/admin/billing/s3-enable`;
    // let apiURL = `https://dev.api.swayam.cloud/v3/admin/billing/cfrc-test`;

      let result = await this.apiService.postDataPromis(apiURL, data, header);

      if (result.status == '1' || result.s == '1') {
        this.notifier.alert('Success', '', result.msg, 'success', 5000);
        this.close({
          type: 'all',
        });
      } else {
        this.notifier.alert('Info', '', result.msg, 'info', 5000);
      }

      this.notifier.loading(false);
    } else {
      this.notifier.loading(true);
      let data: any = {
        action: 'update',
        data: [
          {
            accountId: this.ri.accountId,
            uuid: this.ri.uuid,
            discountType: this.ri.discountType,
            discount: this.ri.discountPercentage,
            usageType: this.ri.usageType,
            ppu: '-',
          },
        ],
      };

      let header = {
        Authorization: localStorage.getItem('t'),
      };
      let apiURL =
        'https://dev.api.swayam.cloud/v3/admin/billing/cfrc-enable-v3';

      let result = await this.apiService.postDataPromis(apiURL, data, header);

      if (result.status == '1' || result.s == '1') {
        this.notifier.alert('Success', '', result.msg, 'success', 5000);
        this.close(true);
      } else {
        this.notifier.alert('Info', '', result.msg, 'info', 5000);
      }

      this.notifier.loading(false);
    }
  }

  close(dataAction: any) {
    if (dataAction) {
      this.hideModel.emit(dataAction);
    } else {
      this.hideModel.emit(dataAction);
    }
  }
}
