import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
//import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { APIService } from './../api/api.service';
import { NotifierService } from './../_services/notifier.service';

import searchOptions from './search';

import data from './../data/searchData';

declare var $: any;

export let CBSPoralAccess: any = ['cbs/mastersummary'];

export let regions = [
  { id: 'ap-south-1', name: 'Asia Pacific (Mumbai)ap-south-1' },
  { 'id': 'ap-south-2', name: 'Asia Pacific (Hyderabad) ap-south-2' },
  { id: 'us-east-1', name: 'US East (N. Virginia)us-east-1' },
  { id: 'us-east-2', name: 'US East (Ohio)us-east-2' },
  { id: 'us-west-1', name: 'US West (N. California)us-west-1' },
  { id: 'us-west-2', name: 'US West (Oregon)us-west-2' },
  { id: 'ap-northeast-3', name: 'Asia Pacific (Osaka-Local)ap-northeast-3' },
  { id: 'ap-northeast-2', name: 'Asia Pacific (Seoul)ap-northeast-2' },
  { id: 'ap-southeast-1', name: 'Asia Pacific (Singapore)ap-southeast-1' },
  { id: 'ap-southeast-2', name: 'Asia Pacific (Sydney)ap-southeast-2' },
  { id: 'ap-northeast-1', name: 'Asia Pacific (Tokyo)ap-northeast-1' },
  { id: 'ca-central-1', name: 'Canada (Central)ca-central-1' },
  { id: 'eu-central-1', name: 'Europe (Frankfurt)eu-central-1' },
  { id: 'eu-west-1', name: 'Europe (Ireland)eu-west-1' },
  { id: 'eu-west-2', name: 'Europe (London)eu-west-2' },
  { id: 'eu-west-3', name: 'Europe (Paris)eu-west-3' },
  { id: 'eu-north-1', name: 'Europe (Stockholm)eu-north-1' },
  { id: 'me-south-1', name: 'Middle East (Bahrain)me-south-1' },
  { id: 'sa-east-1', name: 'South America (São Paulo)sa-east-1' },
  { id: 'ap-east-1', name: 'Asia Pacific (Hong Kong)ap-east-1' },
  { id: 'af-south-1', name: 'Africa (Cape Town)af-south-1' },
  { id: 'ap-southeast-3', name: 'Asia Pacific (Jakarta)ap-southeast-3' },
  { id: 'eu-south-1', name: 'Europe (Milan)eu-south-1' },
];
@Component({
  selector: 'app-dash',
  templateUrl: './dash.component.html',
  styleUrls: ['./dash.component.css'],
})
export class DashComponent implements OnInit, OnDestroy {
  userId = localStorage.getItem('eId');
  username = localStorage.getItem('un');
  cbsAccess: boolean = false;
  ut = localStorage.getItem('ut');
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  searchOptions_: any = searchOptions;
  agl: any;
  expanded: any = {
    infra: false,
    security: false,
    maintenance: false,
  };

  BOMAccess: boolean = this.urlPrefix === 'admin';

  betaAccess: boolean = false;

  accessType: string = localStorage.getItem('acT');

  accountGroups: any = {
    list: [],
    selected: '',
  };
  clientNames: any = {
    list: [],
    selected: '',
  };
  accountIds: any = {
    list: [],
    selected: '',
  };
  searchValue: string = '';
  recentSearchs: any = [];
  regions = regions;

  selectedRegion = 'Asia Pacific (Mumbai)ap-south-1';
  selectedRegionId = 'ap-south-1';

  accountIdSearchTerm: any;
  clientNameSearchTerm: any;
  accountGroupSearchTerm: any;
  accessList: any = localStorage.getItem('menuList');
  showSearchResult = false;
  searchData: any = data;
  searchResults: any;
  CBSPortal: boolean = window.location.href.indexOf('cbs.swayam.cloud') > -1;
  DevPortal: boolean =
    window.location.href.indexOf('swayam-pipeline-portal-internal') > -1;

  CBSAccessList: any = CBSPoralAccess;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiServer: APIService,
    private notifier: NotifierService
  ) {}

  checkForAccess(path: string) {
    if (!this.CBSPortal) {
      return true;
    }
    if (this.CBSAccessList.indexOf(path) > -1) {
      return true;
    }
    return false;
  }

  async ngOnInit() {
    if (this.accessList) {
      try {
        this.accessList = JSON.parse(this.accessList).map((menu: any) => {
          return menu.toLowerCase();
        });
      } catch (error) {
        this.accessList = [];
      }
    }

    // this.fetchRecentSearch();
    // if (this.ut == 'user') {
    //   this.urlPrefix = 'client';
    // }
    this.checkForBetaAccess();

    if (!this.CBSPortal) {
      await this.loadHeader();
    } else {
      this.notifier.changeAccountIdNotification('1234567890');
    }
    //localStorage.setItem('agl', JSON.stringify(this.agl));
    // if (!localStorage.getItem('selectedRegion')) {
    //   localStorage.setItem('selectedRegion', this.regions[0].name);
    //   localStorage.setItem('selectedRegionId', this.regions[0].id);
    //   localStorage.setItem('regionId', this.regions[0].id);
    //   this.notifier.changeRegionNotification(
    //     localStorage.getItem('selectedRegionId')
    //   );
    // } else {
    //   this.notifier.changeRegionNotification(
    //     localStorage.getItem('selectedRegionId')
    //   );
    // }
  }

  async checkForBetaAccess() {
    let data = {
      action: 'check_user',
      uid: localStorage.getItem('eId'),
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };

    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/users/checkdevuser`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    if (result.s == '1' || result.status == '1') {
      if (result.msg == 'Y') {
        this.betaAccess = true;
      } else {
        this.betaAccess = false;
      }
    }
    if (this.CBSPortal && !this.cbsAccess) {
      this.logout();
    }
  }

  async fetchAccountGroups() {
    return new Promise(async (resolve: any, reject: any) => {
      this.notifier.loading(true);
      let data = {
        action: 'fetchAllAccountGroups2',
        clientId: localStorage.getItem('eId'),
        userId: localStorage.getItem('eId'),
        ut: localStorage.getItem('ut'),
      };
      let header = {
        Authorization: localStorage.getItem('t'),
      };
      let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/users/fetchaccountgroupsv2`;

      let result = await this.apiServer.postDataPromis(apiURL, data, header);

      if (result.s == '1') {
        result.agl = result.agl.sort(function (a: any, b: any) {
          if (a.groupName < b.groupName) {
            return -1;
          }
          if (a.groupName > b.groupName) {
            return 1;
          }
          return 0;
        });
        if (result.agl) {
          localStorage.setItem('accountGroups', JSON.stringify(result.agl));
          await this.loadAccountGroups();
          resolve({ s: '1', message: 'Account Groups Fetched' });
        } else {
          reject({ s: '0', message: 'Account Groups Not Found' });
        }
      }
      resolve(result);
    });
  }

  async loadAccountGroups() {
    return new Promise(async (resolve: any, reject: any) => {
      const accountGroups = localStorage.getItem('accountGroups');
      try {
        const data = JSON.parse(accountGroups);
        if (data.length > 0) {
          this.accountGroups.list = data.sort(function (a: any, b: any) {
            if (a.groupName < b.groupName) {
              return -1;
            }
            if (a.groupName > b.groupName) {
              return 1;
            }
            return 0;
          });
          const prevAccountGroup = localStorage.getItem('selectedAccountGroup');
          if (prevAccountGroup) {
            await this.setAccountGroup(prevAccountGroup);
          } else {
            await this.setAccountGroup(data[0].groupName);
          }
          resolve({ s: '1', message: 'ACCOUNT GROUPS LOADED' });
        } else {
          await this.fetchAccountGroups();
        }
      } catch (err) {
        console.log(err);
        await this.fetchAccountGroups();
      }
    });
  }

  async setAccountGroup(groupName: any) {
    this.accountIdSearchTerm = null;
    this.clientNameSearchTerm = null;
    this.accountGroupSearchTerm = null;
    this.accountGroups.selected = groupName;
    const prevAccountGroup = localStorage.getItem('selectedAccountGroup');
    if (prevAccountGroup) {
      if (prevAccountGroup != groupName) {
        await this.fetchClientList(groupName);
      } else {
        this.loadClientList(groupName);
      }
    } else {
      await this.fetchClientList(groupName);
    }
    localStorage.setItem('selectedAccountGroup', groupName);
  }

  async fetchClientList(groupName: any) {
    return new Promise(async (resolve: any, reject: any) => {
      this.notifier.loading(true);
      let data = {
        action: 'fetchSwayamClientList',
        clientId: localStorage.getItem('eId'),
        userId: localStorage.getItem('eId'),
        groupName: groupName,
        ut: 'admin',
      };
      let header = {
        Authorization: localStorage.getItem('t'),
      };
      //https://api.swayam.cloud/v3/admin/support
      let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/users/fetchaccountgroupsv2`;

      let result = await this.apiServer.postDataPromis(apiURL, data, header);

      if (result.s == '1') {
        if (result.agl) {
          result.agl[0]['accountGroup']['accountGroupList'][
            'accountGroupDetails'
          ] = result.agl[0]['accountGroup']['accountGroupList'][
            'accountGroupDetails'
          ].sort(function (a: any, b: any) {
            if (a.cmpName < b.cmpName) {
              return -1;
            }
            if (a.cmpName > b.cmpName) {
              return 1;
            }
            return 0;
          });
          localStorage.setItem(
            'clientList',
            JSON.stringify(
              result.agl[0]['accountGroup']['accountGroupList'][
                'accountGroupDetails'
              ]
            )
          );
          await this.loadClientList(groupName);
          resolve({ s: '1', message: 'Client List Fetched' });
        } else {
          reject({ s: '0', message: 'Client List Not Found' });
        }
      }
      resolve(result);
    });
  }

  async loadClientList(groupName: any) {
    return new Promise(async (resolve, reject) => {
      const clientList = localStorage.getItem('clientList');
      try {
        const data = JSON.parse(clientList);
        if (data.length > 0) {
          this.clientNames.list = data.map((client: any) => {
            client['cmpName'] = client['cmpName'].trim();
            return client;
          });
          this.clientNames.list = data.sort(function (a: any, b: any) {
            if (a.cmpName < b.cmpName) {
              return -1;
            }
            if (a.cmpName > b.cmpName) {
              return 1;
            }
            return 0;
          });
          const prevClientList = localStorage.getItem('selectedClientList');
          if (
            prevClientList &&
            localStorage.getItem('selectedAccountGroup') == groupName
          ) {
            await this.setClientList(prevClientList);
          } else {
            await this.setClientList(data[0]['emailId']);
          }
          resolve({ s: '1', message: 'CLIENT LIST LOADED' });
        } else {
          await this.fetchClientList(groupName);
        }
      } catch (err) {
        console.log(err);
        await this.fetchClientList(groupName);
      }
    });
  }

  async setClientList(clientName: any) {
    $('#wait').show();
    this.accountIdSearchTerm = null;
    this.clientNameSearchTerm = null;
    this.accountGroupSearchTerm = null;
    this.clientNames.selected = this.clientNames.list.find((client: any) => {
      return client.emailId == clientName;
    })['cmpName'];
    const prevClientList = localStorage.getItem('selectedClientList');
    if (prevClientList) {
      if (prevClientList != clientName) {
        await this.fetchAccountList(clientName);
      } else {
        this.loadAccountList(clientName);
      }
    } else {
      await this.fetchAccountList(clientName);
    }
    localStorage.setItem('selectedClientList', clientName);
    return true;
  }

  async fetchAccountList(clientName: any) {
    return new Promise(async (resolve: any, reject: any) => {
      this.notifier.loading(true);
      let data = {
        action: 'fetchSwayamClientAccountList',
        clientEmailId: clientName,
        userId: localStorage.getItem('eId'),
        groupName: this.accountGroups.selected,
        ut: 'admin',
      };
      let header = {
        Authorization: localStorage.getItem('t'),
      };
      //https://api.swayam.cloud/v3/admin/support
      let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/users/fetchaccountgroupsv2`;

      let result = await this.apiServer.postDataPromis(apiURL, data, header);

      if (result.s == '1') {
        if (result.otherDetailsList) {
          result.otherDetailsList = result.otherDetailsList.sort(function (
            a: any,
            b: any
          ) {
            if (a.accountName < b.accountName) {
              return -1;
            }
            if (a.accountName > b.accountName) {
              return 1;
            }
            return 0;
          });
          localStorage.setItem(
            'accountListNew',
            JSON.stringify(result.otherDetailsList)
          );
          await this.loadAccountList(clientName);
          resolve({ s: '1', message: 'Account Groups Fetched' });
        } else {
          reject({ s: '0', message: 'Account Groups Not Found' });
        }
      }
      resolve(result);
    });
  }

  async loadAccountList(clientList: any) {
    return new Promise(async (resolve, reject) => {
      const accountList = localStorage.getItem('accountListNew');
      try {
        const data = JSON.parse(accountList);
        if (data.length > 0) {
          this.accountIds.list = data.sort(function (a: any, b: any) {
            if (a.accountName < b.accountName) {
              return -1;
            }
            if (a.accountName > b.accountName) {
              return 1;
            }
            return 0;
          });
          const prevAccountList = localStorage.getItem('accountId');
          if (
            prevAccountList &&
            localStorage.getItem('selectedClientList') == clientList
          ) {
            await this.setAccountList(prevAccountList);
          } else {
            await this.setAccountList(data[0]['accountId']);
          }
          resolve({ s: '1', message: 'ACCOUNT LIST LOADED' });
        } else {
          await this.fetchClientList(this.accountGroups.selected);
        }
      } catch (err) {
        console.log('LOADING ACCOUNT LIST', err);
        await this.fetchClientList(this.accountGroups.selected);
      }
    });
  }

  async setAccountList(accountId: any) {
    this.accountIdSearchTerm = null;
    this.clientNameSearchTerm = null;
    this.accountGroupSearchTerm = null;
    const accountList = localStorage.getItem('accountListNew');
    try {
      const data = JSON.parse(accountList);
      const account = data.find((account: any) => {
        return account.accountId == accountId;
      });
      this.accountIds.selected = account.accountName;
      localStorage.setItem('accountId', account.accountId);
      if (account) {
        localStorage.setItem('swayam_jira_org_id', account.jira_org_id);
        localStorage.setItem('swayam_jira_org_name', account.jira_org_name);
        localStorage.setItem('swayam_jira_project_id', account.jira_project_id);
        localStorage.setItem(
          'swayam_jira_project_key',
          account.jira_project_key
        );
        localStorage.setItem(
          'swayam_jira_project_name',
          account.jira_project_name
        );
        localStorage.setItem(
          'swayam_site_247_project_id',
          account.site_247_project_id
        );
        localStorage.setItem(
          'site_247_project_name',
          account.site_247_project_name
        );
        localStorage.setItem('monitoringUrl', account.monitoringUrl);
      }
    } catch (err) {}
    this.selectAccountId(
      this.accountIds.list.find((account: any) => {
        return account.accountName == this.accountIds.selected;
      })
    );
    return true;
  }

  //TODO : save on Change

  checkIfAvaForUser(routeName: String) {
    if (localStorage.getItem('role') == 'Client') {
      if (routeName.includes(':') || localStorage.getItem('ut') == 'admin') {
        return true;
      }
      routeName = routeName.toLowerCase().split(':')[0];
      if (this.accessList.indexOf(routeName) > -1) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  filterResults(array: any) {
    if (this.searchValue == '') {
      return [];
    }
    let count: number = 0;
    return array.filter((search: any) => {
      let check: boolean = false;
      if (count < 5) {
        for (let i = 0; i < search.slugs.length; i++) {
          if (search.slugs[i].indexOf(this.searchValue.toLowerCase()) > -1) {
            check = true;
            count = count + 1;
          }
        }
      } else {
        return false;
      }
      return check;
    });
  }

  fetchRecentSearch() {
    let storage = localStorage.getItem('searchResults');
    let dt: any;
    if (storage) {
      dt = JSON.parse(storage);
    } else {
      dt = [];
    }
    this.recentSearchs = dt;
  }

  setRecentSearch(value: any) {
    new Promise((resolve: any) => {
      let storage = localStorage.getItem('searchResults');
      let dt: any;
      if (storage) {
        dt = JSON.parse(storage);
        let check = dt.findIndex((i: any) => {
          return i.url == value.url;
        });
        if (check >= 0) {
          dt.splice(check, 1);
        }
        if (dt.length > 0) {
          dt.unshift(value);
        } else {
          dt.push(value);
        }
        if (dt.length > 5) {
          dt = dt.slice(0, 5);
        }
      } else {
        dt = [value];
      }
      localStorage.setItem('searchResults', JSON.stringify(dt));
      this.recentSearchs = dt;
      resolve(true);
    });
  }

  async navigateTo(param: any) {
    await this.setRecentSearch(param);
    this.searchValue = '';
    this.router.navigateByUrl(param.url);
  }

  async loadHeader() {
    this.notifier.loading(true);
    if (!localStorage.getItem('regionId')) {
      this.setRegion(this.regions[0]);
    } else {
      this.setRegion(
        this.regions.find((region: any) => {
          return region.id == localStorage.getItem('regionId');
        })
      );
    }
    if (localStorage.getItem('role') == 'Client') {
      let data = localStorage.getItem('accountIdData');
      if (data) {
        this.accountIds = {
          selected: JSON.parse(data).selected,
          list: JSON.parse(data).list,
        };
        localStorage.setItem('accountId', this.accountIds.list[0].accountId);
        localStorage.setItem('accountIdData', JSON.stringify(this.accountIds));
        this.selectAccountId(
          JSON.parse(data).list.find((account: any) => {
            return this.accountIds.selected.indexOf(account.accountId) > -1;
          })
        );
        return true;
      } else {
        let data = {
          emailId: this.userId,
          clientName: localStorage.getItem('un'),
        };
        let header = {
          Authorization: localStorage.getItem('t'),
        };
        let apiUrl = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/listawsaccountsv2`;

        let result = await this.apiServer.postDataPromis(apiUrl, data, header);
        if (result.s == '1' || result.status == '1') {
          this.accountIds = {
            selected: result.list[0]['otherdetail'][0].accountName,
            list: result.list[0]['otherdetail'],
          };
          localStorage.setItem('accountId', this.accountIds.list[0].accountId);
          localStorage.setItem(
            'accountIdData',
            JSON.stringify(this.accountIds)
          );
          this.selectAccountId(this.accountIds.list[0]);
        }
        this.notifier.loading(false);

        return true;
      }
    }

    await this.loadAccountGroups();
    return true;
    let agl = localStorage.getItem('agl');
    if (agl) {
      console.log('agl', agl);
      this.agl = JSON.parse(agl);

      this.loadAGL();
      return true;
    }

    this.notifier.loading(true);
    let data: any = {
      action: 'fetchAccountGroupsAndAccIDs',
      userId: this.userId,
      ut: this.ut,
    };

    let header = {
      Authorization: localStorage.getItem('t'),
    };

    //https://api.swayam.cloud/v3/admin/users/fetchaccountgroupsv2
    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/users/fetchaccountgroupsv2`;
    console.log('FETCHING HEADER DATA', this.urlPrefix);
    if (this.urlPrefix == 'admin') {
      // console.log('URL ADMIN:', this.urlPrefix);
      // apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/users/fetchaccountgroupsv2`;
      console.log('I AM ADMIN');
      await this.loadAccountGroups();
      return true;
    } else {
      console.log('URL ADMIN:', this.urlPrefix);
      apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/listawsaccountsv2`;
    }

    let result = await this.apiServer.postDataPromis(apiURL, data, header);

    console.log(result);

    if (result.s == '1' || result.status == '1') {
      this.notifier.loading(false);
      if (this.urlPrefix == 'client') {
        this.accountIds = {
          selected: result.list[0]['otherdetail'][0].accountName,
          list: result.list[0]['otherdetail'],
        };
        localStorage.setItem('accountId', this.accountIds.list[0].accountId);
        localStorage.setItem('accountIdData', JSON.stringify(this.accountIds));
        this.selectAccountId(this.accountIds.list[0]);
        return true;
      }

      this.agl = result.agl;

      localStorage.setItem('agl', JSON.stringify(result.agl));

      //
      this.accountGroups = {
        selected: this.agl[0].groupName,
        list: [],
      };

      for (let i = 0; i < this.agl.length; i++) {
        this.accountGroups.list.push(this.agl[i]);
      }

      localStorage.setItem('selectedAccountGroup', this.accountGroups.selected);
      localStorage.setItem(
        'selectedAccountGroupData',
        JSON.stringify(this.accountGroups)
      );

      console.log(
        'this.accountGroups',
        JSON.stringify(
          this.accountGroups.list[0].accountGroup.accountGroupList
            .accountGroupDetails,
          null,
          2
        )
      );

      let value =
        this.accountGroups.list[0].accountGroup.accountGroupList
          .accountGroupDetails;
      this.clientNames = {
        selected: value[0].cmpName,
        list: value,
      };

      let clientName = this.clientNames.list[0];

      this.clientNames.selected = clientName.cmpName;

      localStorage.setItem('selectedClientName', this.clientNames.selected);
      localStorage.setItem(
        'selectedClientNameData',
        JSON.stringify(this.clientNames)
      );

      this.accountIds = {
        selected: clientName.accList[0].accountName,
        list: clientName.accList,
      };

      localStorage.setItem('selectedaccountIds', this.accountIds.selected);
      localStorage.setItem('accountId', clientName.accList[0].accountId);
      localStorage.setItem('accountIdData', JSON.stringify(this.accountIds));

      //localStorage.removeItem('selectedAccountGroup');
      //localStorage.removeItem('selectedClientName');
      //localStorage.removeItem('selectedaccountIds');

      //localStorage.removeItem("selectedRegion");

      this.notifier.loading(false);

      //this.accountIds = {
      //  selected: "",
      //  list: []
      //};

      //this.regions = {
      //  selected: "",
      //  list: []
      //};
    } else {
      this.notifier.alert('Error', 'Error', 'Error Loading Account Groups');
      this.notifier.loading(false);
    }

    return true;
  }

  loadAGL() {
    this.accountGroups = JSON.parse(
      localStorage.getItem('selectedAccountGroupData')
    );
    this.clientNames = JSON.parse(
      localStorage.getItem('selectedClientNameData')
    );
    this.accountIds = JSON.parse(localStorage.getItem('accountIdData'));

    this.selectedRegion = localStorage.getItem('selectedRegion');
    //localStorage.setItem("selectedRegion", regions.name);
    localStorage.getItem('selectedRegionId');

    this.notifier.changeRegionNotification(
      localStorage.getItem('selectedRegionId')
    );

    //for (var i = 0; i < this.agl[0].length; i++) {
    //  if()
    //}
    //this.accountGroups = {
    //  selected: this.agl[0].groupName,
    //  list: []
    //};

    //for (let i = 0; i < this.agl.length; i++) {
    //  this.accountGroups.list.push(this.agl[i]);
    //}
  }

  async selectAccountGroup(accountGroup: any, refresh = true) {
    this.accountGroups.selected = accountGroup.groupName;

    this.setAccountGroup(this.accountGroups.selected);
    return;
    let value = accountGroup.accountGroup.accountGroupList.accountGroupDetails;
    console.log('accountGroup', value);

    localStorage.setItem('selectedAccountGroup', this.accountGroups.selected);
    localStorage.setItem(
      'selectedAccountGroupData',
      JSON.stringify(this.accountGroups)
    );

    this.clientNames = {
      selected: this.accountGroups.selected,
      list: value,
    };

    //for (let i = 0; i < this.clientNames.length; i++) {
    //  if (this.clientNames.list[i].cmpName == this.clientNames.selected) {
    this.selectclientName(this.clientNames.list[0], refresh);
    //      break;
    //    }

    //}
  }

  async selectclientName(clientName: any, refresh = true) {
    this.clientNames.selected = clientName.cmpName;

    // if (!clientName.accList || clientName.accList.length == 0) {
    //   this.notifier.alert('Error', 'Error', 'No Account Found!');

    //   this.accountIds = {
    //     selected: '',
    //     list: [],
    //   };
    //   return;
    // }

    // this.accountIds = {
    //   selected: clientName.accList[0].accountName,
    //   list: clientName.accList,
    // };

    localStorage.setItem('selectedClientName', this.clientNames.selected);
    localStorage.setItem(
      'selectedClientNameData',
      JSON.stringify(this.clientNames)
    );

    this.setClientList(clientName.emailId);
    return;
    this.notifier.changeAccountIdNotification(
      this.accountIds.list[0].accountId
    );

    this.selectAccountId(this.accountIds.list[0]);
  }

  searchOnList(data: string, pattern: string) {
    if (!pattern) return true;

    if (data.toLowerCase().indexOf(pattern.toLowerCase()) != -1) return true;
    return false;
  }

  selectAccountId(accountId: any) {
    this.notifier.loading(false);
    this.accountIds.selected =
      accountId.accountName + ` (${accountId.accountId})`;

    localStorage.setItem('selectedaccountIds', this.accountIds.selected);
    localStorage.setItem('accountId', accountId.accountId);
    localStorage.setItem('accountIdData', JSON.stringify(this.accountIds));

    this.notifier.changeAccountIdNotification(accountId.accountId);
  }

  setRegion(regions: any) {
    this.selectedRegion = regions.name;
    localStorage.setItem('selectedRegion', regions.name);
    localStorage.setItem('selectedRegionId', regions.id);
    localStorage.setItem('regionId', regions.id);

    this.notifier.changeRegionNotification(regions.id);
  }

  async logout() {
    this.notifier.loading(true);
    let data = { eId: this.userId, ut: this.urlPrefix };

    let header = {
      Authorization: localStorage.getItem('t'),
    };

    let apiURL = `${APIService.API_ENDPOINTV3}/${this.urlPrefix}/users/logout`;

    let result = await this.apiServer.postDataPromis(apiURL, data, header);
    if (result.status == '1' || result.s == '1') {
      this.notifier.alert('Success', '', result.msg, 'success', 5000);
    }
    localStorage.clear();
    this.router.navigateByUrl('/login');
    this.notifier.loading(false);
  }

  hideLeftMenu() {
    $('.leftmenu').addClass('leftmenuhidden');
    $('.rightcontent').addClass('leftmenuhidden');
    $('#menuClosebtn').hide();
    $('#menuShowbtn').show();
  }

  showLeftMenu() {
    $('.leftmenu').removeClass('leftmenuhidden');
    $('.rightcontent').removeClass('leftmenuhidden');
    $('#menuClosebtn').show();
    $('#menuShowbtn').hide();
  }

  showSearch() {
    this.showSearchResult = true;
  }

  hideSearch() {
    this.showSearchResult = false;
  }

  onSearchChange() {
    let searchValue = this.searchValue;
    if (!searchValue) return;
    searchValue = searchValue.toLowerCase();
    this.searchResults = this.searchData.items.filter((a: any) => {
      if (a.keys.indexOf(searchValue) != -1) {
        return true;
      } else {
        return false;
      }
    });
  }

  goTO(link: string) {
    this.showSearchResult = false;
    this.router.navigateByUrl(link);
  }

  expandDropdown(event: any, value: string) {
    event.stopPropagation();
    this.expanded[value] = !this.expanded[value];
  }

  ngOnDestroy(): void {}
}
