import {
  Component,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { APIService } from '../../../../api/api.service';
import { NotifierService } from '../../../../_services/notifier.service';
import { regions } from '../../../../dash/dash.component';

declare let $: any;
declare let flatpickr: any;

@Component({
  selector: 'enableerp-update',
  templateUrl: './update_erp.component.html',
  styleUrls: ['./update_erp.component.css'],
})


export class UpdateERPComponent implements OnInit, OnDestroy {
  additionalContacts: string[] = [''];
  userId = localStorage.getItem('eId');
  ut = localStorage.getItem('ut');
  regions: any = regions;
  urlPrefix: any = localStorage.getItem('role') == 'Admin' ? 'admin' : 'client';
  writeAccess: boolean =
    localStorage.getItem('acT') == 'readandwrite' ||
    this.urlPrefix == 'admin' ||
    localStorage.getItem('ut') == 'admin';
  loading = true;
  accountId: any;
  regionId: any;
  dateRange: any;
  selectAll: boolean = false;
  RITypes: any = [];
  currentMessage: any;
  @Output() hideModel = new EventEmitter<any>();
  @Input() ri: any;
  @Input('action') action: any;
  linkedAccounts: any ={};
  selectedAccounts: any;
  customers:any = [];
  accounts: any;
  constructor(
    private apiService: APIService,
    private notifier: NotifierService
  ) {}

  ngOnInit(): void {
    this.ri = { ...this.ri };
    console.log('ri',this.ri);
    this.accountId = localStorage.getItem('accountId');
    this.regionId = localStorage.getItem('regionId');
  

    this.currentMessage = this.notifier.currentMessage.subscribe((msg) => {
      let d = JSON.parse(msg);
      if (d.value == null) {
        return;
      }
      if (d.key == 'accountId') {
        this.accountId = d.value;
        this.load();
      } else if (d.key == 'regionId') {
        this.regionId = d.value;
        this.load();
      }
      else if (d.key == 'accountIdData'){
        this.linkedAccounts = d.value;
        this.load();
      }
    });
    flatpickr('#dataRangePicker', {
      dateFormat: 'Y-m-d',
      mode: 'range',
      showMonths: 2,
      defaultDate: this.dateRange ? this.dateRange.split(' to ') : null,
      onChange: (selectedDates: any, dateStr: any, instance: any) => {
        if (!dateStr.includes('to')) return;
        this.dateRange = dateStr;
      },
    });

      this.dateRange = this.ri.startDate + ' to ' + this.ri.endDate;

  }


  disc: any;
  load() {
    // this.updateEntry()
  }
  
  async updateEntry() {
    if (this.ri.mid == null || this.ri.mid == undefined || this.ri.mid == '') {
      console.log('Validation failed: Master ID is missing or invalid', this.ri.mid);
      this.notifier.alert('Error', '', 'Master ID is required', 'error', 5000);
      return;
    }
    if (this.ri.customerName == null || this.ri.customerName == undefined || this.ri.customerName == '') {
      console.log('Validation failed: Customer Name is missing or invalid', this.ri.customerName);
      this.notifier.alert('Error', '', 'Customer Name is required', 'error', 5000);
      return;
    }
    if (this.ri.customerId == null || this.ri.customerId == undefined || this.ri.customerId == '') {
      console.log('Validation failed: Customer ID is missing or invalid', this.ri.customerId);
      this.notifier.alert('Error', '', 'Customer Id is required', 'error', 5000);
      return;
    }
    if (this.ri.erpOrderNumber == null || this.ri.erpOrderNumber == undefined || this.ri.erpOrderNumber == '') {
      console.log('Validation failed: Order Number is missing or invalid', this.ri.erpOrderNumber);
      this.notifier.alert('Error', '', 'Order Number is required', 'error', 5000);
      return;
    }      
    this.notifier.loading(true);
      let data = {
        action: "addOrUpdate",
        recordData: {
                    accountId: this.ri.mid,
                    customerName: this.ri.customerName,
                    customerId: this.ri.customerId,
                    erpOrderNumber: this.ri.erpOrderNumber,
                    currencyCode: this.ri.currencyCode
                  }
                }
      let header = {
        Authorization: localStorage.getItem('t'),
      };

    let apiURL = `https://dev.api.swayam.cloud/v3/admin/billing/erp`;

      let result = await this.apiService.postDataPromis(apiURL, data, header);

      if (result.status == '1' || result.s == '1') {
        this.notifier.alert('Success', '', result.msg, 'success', 5000);
        this.close(true);
      } else {
        this.notifier.alert('Info', '', result.msg, 'info', 5000);
      }
      this.notifier.loading(false);
  }

  ngOnDestroy(): void {}

  trackByFn() {
    return true;
  }

  showInvalidFieldNotificationall() {
    if (this.ri.mid == null || this.ri.mid == undefined || this.ri.mid == '') {
      console.log('Validation failed: Master ID is missing or invalid', this.ri.mid);
      this.notifier.alert('Error', '', 'Master ID is required', 'error', 5000);
      return;
    }
    if (this.ri.customerName == null || this.ri.customerName == undefined || this.ri.customerName == '') {
      console.log('Validation failed: Customer Name is missing or invalid', this.ri.customerName);
      this.notifier.alert('Error', '', 'Customer Name is required', 'error', 5000);
      return;
    }
    if (this.ri.customerId == null || this.ri.customerId == undefined || this.ri.customerId == '') {
      console.log('Validation failed: Customer ID is missing or invalid', this.ri.customerId);
      this.notifier.alert('Error', '', 'Customer Id is required', 'error', 5000);
      return;
    }
    if (this.ri.erpOrderNumber == null || this.ri.erpOrderNumber == undefined || this.ri.erpOrderNumber == '') {
      console.log('Validation failed: Order Number is missing or invalid', this.ri.erpOrderNumber);
      this.notifier.alert('Error', '', 'Order Number is required', 'error', 5000);
      return;
    }
  }
  



  close(dataAction: any) {
    if (dataAction) {
      this.hideModel.emit(dataAction);
    } else {
      this.hideModel.emit(dataAction);
    }
  }
}
