import {
  Component,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { APIService } from '../../../../../../api/api.service';
import { NotifierService } from '../../../../../../_services/notifier.service';
declare let $: any;
declare let flatpickr: any;

import * as moment from 'moment';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: [
    '../../../../../../views/cbs/enableri/update/update.enableri.component.css',
    './popup.component.css',
  ],
})
export class NonNhaPopUpComponent implements OnInit {
  headers_pop: { id: string; name: string }[];
  serviceData: any = [];
  showPopup: boolean;
  service_Name: any;
  popupData: any[];
  lek: any;
  date: any;
  accountId: any;
  @Input() mid: any;
  @Input() popupTitle: any;
  selectedService: any;
  @Output() hidemodel = new EventEmitter<void>();
  //   @Input() selectedClientName: any;

  constructor(
    private apiService: APIService,
    private notifier: NotifierService
  ) {}
  ngOnInit(): void {
    this.accountId = localStorage.getItem('accountId');
    console.log('accountId', this.accountId);
    this.openPopup(this.popupTitle);
    this.date = moment()
      .subtract(1, 'month')
      .startOf('month')
      .format('MM-YYYY');
    // setTimeout(()=>{
    // this.serviceNames();

    // }, 10);
  }

  async openPopup(serviceName: any) {
    this.notifier.loading(true);
    await this.setHeadersBasedOnService(serviceName);
    console.log('headers_pop', this.headers_pop);
    const serviceMapping: { [key: string]: string } = {
      'Amazon Simple Storage Service': 's3',
      'Amazon Relational Database Service': 'rds',
      'Amazon Elastic Compute Cloud': 'ec2',
      'Amazon CloudFront': 'cfrc',
    };

    // Set the selected service's abbreviated name
    this.selectedService = serviceMapping[serviceName] || serviceName;
    console.log('selected service in popup', this.selectedService);
    //   this.selectedClientName = serviceName;
    this.notifier.loading(true);
    // this.popupData = [];
    this.service_Name = serviceName;
    if (this.lek == undefined) {
      this.notifier.loading(true);

      this.fetchDetails(serviceName);
      this.notifier.loading(false);
    }
    // this.fetchDetails(serviceName);
    // Set a flag to show the popup

    console.log(
      'serviceData[selectedService]',
      this.serviceData[this.selectedService]
    );
    this.showPopup = true;

    this.notifier.loading(false);
  }

  async setHeadersBasedOnService(serviceName: string) {
    // Define header configurations based on the service name
    if (serviceName === 'Amazon Elastic Compute Cloud') {
      this.headers_pop = [
        { id: 'instanceId', name: 'Instance ID' },

        { id: 'instanceName', name: 'Instance Name' },

        { id: 'instanceType', name: 'Instance Type' },
        { id: 'os', name: 'OS' },
        { id: 'region', name: 'Region' },
      ];
    } else if (serviceName === 'Amazon Relational Database Service') {
      this.headers_pop = [
        // { id: 'key', name: 'Key' },
        { id: 'region', name: 'Region' },
        { id: 'usageType', name: 'Usage Type' },
        { id: 'azType', name: 'Availability Zone Type' },
        { id: 'dbEngine', name: 'DB Engine' },
      ];
    } else if (serviceName === 'Amazon Simple Storage Service') {
      this.headers_pop = [
        // { id: 'key', name: 'Key' },
        { id: 'region', name: 'Region' },
        { id: 'usageType', name: 'Usage Type' },
      ];
    } else if (serviceName === 'Amazon CloudFront') {
      this.headers_pop = [
        // { id: 'key', name: 'Key' },
        { id: 'region', name: 'Region' },
        { id: 'usageType', name: 'Usage Type' },
      ];
    } else {
      // Default headers if the service name does not match
      this.headers_pop = [
        { id: 'serviceName', name: 'Service Name' },
        { id: 'mrrDiscount', name: 'MRR Discount' },
        { id: 'comment', name: 'Comment' },
      ];
    }
  }

  closePopup() {
    this.notifier.loading(true);
    this.service_Name = undefined;
    this.showPopup = false;
    this.popupData = [];
    this.notifier.loading(false);
    this.lek = undefined;
    this.hidemodel.emit();
  }

  async fetchDetails(serviceName: any) {
    this.notifier.loading(true);

    const serviceMapping: { [key: string]: string } = {
      'Amazon Simple Storage Service': 's3',
      'Amazon Relational Database Service': 'rds',
      'Amazon CloudFront': 'cfrc',
      'Amazon Elastic Compute Cloud': 'ec2',
    };

    const abbreviatedServiceName = serviceMapping[serviceName] || serviceName;
    console.log('abbreviatedServiceName', abbreviatedServiceName);
    let data;
    if (this.lek === null || this.lek === undefined || this.lek === '') {
      console.log(
        'serviceData[abbreviatedServiceName]',
        this.serviceData[abbreviatedServiceName]
      );
      this.serviceData[abbreviatedServiceName] = [];
      data = {
        month: this.date,
        childs: [this.accountId],
        mid: this.mid,
        a: abbreviatedServiceName,
      };
    } else {
      data = {
        month: this.date,
        childs: [this.accountId],
        mid: this.mid,
        a: abbreviatedServiceName,
        lek: this.lek,
      };
    }

    let header = { Authorization: localStorage.getItem('t') };
    let apiURL = `https://dev.api.swayam.cloud/v3/admin/billing/mrr`;
    let result = await this.apiService.postDataPromis(apiURL, data, header);

    if (result.s == '1') {
      if (!result.lek) {
        this.serviceData[abbreviatedServiceName] = [
          ...(this.serviceData[abbreviatedServiceName] || []),
          ...result.riDetails,
        ];

        this.lek = result.lek;
      } else {
        this.lek = result.lek;
        this.serviceData[abbreviatedServiceName] = [
          ...(this.serviceData[abbreviatedServiceName] || []),
          ...result.riDetails,
        ];
      }
    } else {
      this.serviceData[abbreviatedServiceName] = [];
      this.lek = null;
    }
    console.log(
      ' this.serviceData[abbreviatedServiceName]',
      this.serviceData[abbreviatedServiceName]
    );
    this.popupData = this.serviceData[abbreviatedServiceName];
    this.notifier.loading(false);
  }
}
