<div class="alarm_screen" style="box-shadow: 1px 3px 1px #80808024">
  <div class="alarm_container">
    <div class="alarm_header">
      <h4 style="text-transform: capitalize">
        {{ action == "update" ? "Update" : "Add" }}Order Form
      </h4>
    </div>
    <div class="alarm_body" style="padding: 1px 25px 5px 25px">

      <div class="row">

        <label>Master Accounts</label>
        <select class="form-control" id="mislist" [(ngModel)]="selectedOption" data-live-search="true"
          [disabled]="action !== 'add'" (change)="childFetch(selectedOption?.clientId)">
          <option *ngFor="let master of masterAccounts" [ngValue]="master">
            {{ master.name }} ({{master.id}})
          </option>
        </select>
      </div>

      <div class="row" *ngIf="selectedOption">
        <div class="flex-between p-0">
          <label>Linked Account List</label>
          <div class="flex-start filters">
            <div class="flex-start">
              <input type="checkbox" id="select_all_accounts" (change)="selectAllchilds($event)" />
              <label for="select_all_accounts">Select All</label>
            </div>
          </div>
        </div>
        <select class="form-control" id="childlist" [(ngModel)]="selectedChild" data-live-search="true"
          (change)="display()" multiple>
          <option *ngFor="let child of childs" [ngValue]="child">
            
            {{ child.name }} ({{ child.id }})
          </option>
        </select>
      </div>

      <div class="row">
        <label for="">Minfy Savings Plans for Compute usage(Hourly Cost)</label>
        <input class="form-control" type="number" name="crossaccount" id="crossaccount" [(ngModel)]="ri.crossAccount">
      </div>
      

      <div class="alarm_actions">
        <div style="display: flex; justify-content: flex-end">
          <button class="btn" (click)="updateEntry()" style="
              background-color: #5864ff;
              color: white;
              text-transform: capitalize;
              margin-right: 20px;
            ">
            {{ action == "add" ? "Add" : action }}
          </button>
          <button class="btn btn-light" (click)="close(false)">Cancel</button>
        </div>
      </div>
    </div>
  </div>

  <style>
    .alarm_screen label {
      padding: 0 !important;
    }
    /* Container styling */
    .switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-left: 10px; /* Optional to add space between text and switch */
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

  </style>
</div>