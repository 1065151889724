<div class="card mt-20" style="border: none; position: relative; height: 100%;">
  <h5 class="card-header">ERP Report</h5>
  <div class="card-body">
    <div style="display: flex; justify-content: flex-end; align-items: center;">
      <div style="display: flex;
          justify-content: center;
          gap:10px;">
        <div style="display: flex; align-items: center; margin-bottom: 10px;margin: auto;">
          <span style="margin-right: 10px;">Month Year:</span>
          <span>
            <input type="text" id="monthDate" class="form-control" />
          </span>
        </div>
        <div>
          <button class="btn btn-light" style="background-color: #5864FF; color: white;margin: auto;" (click)="load()">Refresh</button>
        </div>
      </div>
      
      
      </div>

      <table class="table table-docs table-hover">
        <thead>
          <tr>
            <th>Sl. No.</th>
            <ng-container *ngFor="let h of headers; let i = index">
              <th *ngIf="i < headers.length">{{ h.name }}</th>
            </ng-container>
            <!-- <th>Update</th> -->
          </tr>
        </thead>
  
        <tbody *ngFor="let i of riData; let j = index"
  [ngStyle]="{'display': riData.length > 0 ? 'table-row-group' : 'none'}">
  <tr>
    <td>{{ j + 1 }}</td>
    <td *ngFor="let h of headers">
      <!-- Regular handling for non-'mid' columns -->
      <ng-template [ngIf]="h.id !== 'mid'">
        <span *ngIf="!h.click">
          {{ (h.alter ? h.alter(i, h.id) : i[h.id]) || '-' }}
        </span>
        <span class="btn" *ngIf="h.click" (click)="callFunction(h.click, i)">
          {{ (h.alter ? h.alter(i, h.id) : i[h.id]) || '-' }}
        </span>
      </ng-template>
      
      <!-- Special handling for the 'mid' column -->
      <ng-template [ngIf]="h.id === 'mid'">
        <span class="btn" (click)="childload(i)">
          {{ (h.alter ? h.alter(i, h.id) : i[h.id]) || '-' }}
        </span>
      </ng-template>
    </td>
    
    <!-- <td>
      <span (click)="updateTrigger(i)" class="btn btn-light"><i class="fa fa-pencil"></i></span>
    </td> -->
  </tr>
</tbody>

        <tbody  [ngStyle]="{'display': riData.length == 0 ? 'table-row-group' : 'none'}">
          <tr>
            <td [attr.colspan]="headers && headers.length ? headers.length - 3 + 3 : 3">
              <div style="text-align: center;">No Data Found</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  
  <!-- <div *ngIf="delete" style="position: fixed; top: 0; left: 0; right: 0; bottom: 0; background: rgba(0, 0, 0, 0.5); backdrop-filter: blur(5px); display: flex; align-items: center; justify-content: center;">
    <div class="card" style="height: 200px; width: 60%; padding: 18px; background: white; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);justify-content: space-between;">
      <h4>Are you sure you want to delete?</h4>
      <div style="text-align: end;">
        <button class="btn btn-danger" style="margin: 0px 4px;" (click)="deleteTrigger(indexdelete)">Delete</button>
        <button class="btn" style="margin: 0px 4px; background-color: rgb(228, 230, 232);" (click)="delete = false">Cancel</button>
      </div>
    </div>
  </div>
  <div *ngIf="deleteall" style="position: fixed; top: 0; left: 0; right: 0; bottom: 0; background: rgba(0, 0, 0, 0.5); backdrop-filter: blur(5px); display: flex; align-items: center; justify-content: center;">
    <div class="card" style="height: 200px; width: 60%; padding: 18px; background: white; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);justify-content: space-between;">
      <h4>This Delete action will delete all the entries. You want to continue?</h4>
      <div style="text-align: end;">
        <button class="btn btn-danger" style="margin: 0px 4px;" (click)="deleteTriggerall()">Yes</button>
        <button class="btn" style="margin: 0px 4px; background-color: rgb(228, 230, 232);" (click)="deleteall = false">Cancel</button>
      </div>
    </div>
  </div> -->




    <div *ngIf="popup" class="modal-overlay">
      <div class="card modal-card">
        <h4>Child Account Details</h4>
        <div class="modal-body">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th scope="col">Sl. No.</th>
                <ng-container *ngFor="let h of child_headers">
                  <th scope="col">{{ h.name }}</th>
                </ng-container>
              </tr>
            </thead>
            <tbody [ngStyle]="{'display': child.length > 0 ? 'table-row-group' : 'none'}">
              <tr *ngFor="let item of child; let j = index">
                <td>{{ j + 1 }}</td>
                <td *ngFor="let h of child_headers">
                  {{ item[h.id] || '-' }}
                </td>
  
              </tr>
            </tbody>
            <tbody  [ngStyle]="{'display': child.length == 0 ? 'table-row-group' : 'none'}">
              <tr>
                <td [attr.colspan]="child_headers && child_headers.length ? child_headers.length - 3 + 3 : 3">
                  <div style="text-align: center;">No Data Found</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="closePopup()">Close</button>
        </div>
      </div>
    </div>
  

  
  
  


<enableerp-update *ngIf="updateRI" [action]="updateAction" [ri]="updateRI" (hideModel)="closeUpdateTrigger($event)">
</enableerp-update>